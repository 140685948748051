.dialog-title {
  text-align: center;
  padding: 5px;
}

.dialog-logo {
  height: 50px;
  background-image: url('../static/datayaki-logo.png');
  background-size: contain;
  background-position-x: center;
  background-repeat: no-repeat;
  margin: 20px;
}