@import 'open-props/gradients';

a {
  text-decoration: none;
  color: inherit;
}

.new-hero-banner {
  background:
    radial-gradient(ellipse at 100% 100%, rgb(179 154 245 / 1) 8.33%, rgb(179 154 245 / 0) 67.36%) 100% 100% / 64% 42% no-repeat no-repeat,
    radial-gradient(ellipse at 0% 0%, rgb(255 150 101 / 1) 0%,  19.1%, rgb(69 168 255 / 0) 72.92%) 0% 0% / 52% 42% no-repeat no-repeat,
    radial-gradient(ellipse at 100% 0%, rgb(82 145 255 / 1) 0%, rgb(255 96 53 / 1) 68.4%, rgb(69 168 255 / 0) 85.42%) 100% 0% / 46% 50% no-repeat no-repeat,
    radial-gradient(ellipse at 0% 100%, rgb(255 15 82 / 1) 0%, rgb(254 87 131 / 1) 40.63%, rgb(255 96 53 / 1) 77.08%) 0% 0% / 100% 100% no-repeat no-repeat;
}