@import 'open-props/borders';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: rgb(255, 150, 0);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.footer {
  position: fixed;
  left: 10px;
  bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: rgb(127,127,127);
  opacity: 0.5;
}

.info-container {
  display: flex;
  flex-direction: row;
  font-size: xx-small;
  align-items: center;
  opacity: 0.5;
}
.version-info {
  padding: 5px;
  border: var(--border-size-1) solid lightgray;
  border-radius: var(--radius-2);
}

.feedback-btn {
  cursor: pointer;
  font-size: xx-large;
}

.info-container > a {
  cursor: pointer;
  text-decoration: none;
  color: rgb(127,127,127);
}
