.widget-title {
  font-weight: bold;
  font-size: larger;
  text-align: center;
  padding: 3px;
  flex-grow: 1;
}

.widget-result {
  border-radius: 0px 0px 10px 10px;
  background-color: rgba(255,255,255);
  text-align: center;
  vertical-align: middle;
  padding: 5px;
  flex-grow: 1;
  /* display: flex;
  align-items: center; */
  justify-content: stretch;
  max-height: 300px;
  overflow: scroll;
}

.widget {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 300px;
  /* min-height: 300px; */
}

.flow {
  display: unset;
}

.ask_message_datayaki .widget {
  min-height: unset;
}

.widget-header {
  /* background-color: rgb(0,0,0,0.5);
  color: #FFF; */
  border-radius: 10px 10px 0px 0px;
  display: flex;
  flex-direction: row;
}

.widget-controls {
  text-align: right;
  padding: 3px;
  margin-right: 5px;
}

.widget-controls > a {
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
}