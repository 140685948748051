#layout {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

#app-nav {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-right: 1px solid;
  border-color: #ddd;
  width: 200px;
  transition: 500ms;
}

#app-nav.minimized {
  width: 40px;
}

#q {
  width: calc(100% - 10px);
}

#app-nav #nav-links {
  flex-grow: 1
}

#nav-links > ul {
  padding-inline-start: 0px;
}

#nav-links > ul > li {
  list-style: none;
  margin-left: 0.5em;
  text-align: left;
}