@import 'open-props/animations';
@import 'open-props/gradients';
@import 'open-props/borders';
@import 'open-props/shadows';
@import 'open-props/fonts';

.introjs-overlay {
  pointer-events: none;
}

.introjs-helperLayer {
  pointer-events: none;
}

.demo_header_container {
  display: flex;
  flex-direction: row;
  border-bottom: 1px #eee solid;
  align-items: center;
  box-shadow: var(--shadow-2) var(--gray-2);
}

.demo_header_logo {
  width: 50px;
  height: 50px;
  background-image: url('../static/datayaki-logo-outline.png');
  background-size: cover;
  background-position: middle;
  margin: 10px;
}

.demo_header_user {
  font-size: small;
  margin: 15px;
  cursor: pointer;
}

.demo_header_spacer {
  flex-grow: 1;
}

.dashi_datasources:not(.dashi_datasources:has(div.dashi_datasources_container)) {
  border-radius: var(--radius-conditional-3);
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  filter: drop-shadow(0px 0px 10px rgba(0,0,0,0.25));
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto;
  height: 400px;
  width: 400px;
  color: white;
}

.dashi_datasources:has(div.dashi_datasources_container) {
  /* border-radius: 5px;
  border-style: none;
  border-color: #eee;
  border-width: 1px;
  border-style: solid; */
  border-radius: var(--radius-conditional-2);
  box-shadow: var(--shadow-1);
  /* background-image: var(--gradient-23);
  background-size: cover; */
  /* background-color: rgba(255, 255, 255, 0.2); */
  display: flex;
  flex-direction: row;
  margin: 5px;
  color: gray;
  padding: 5px;
}

.dashi_datasources {
  transition: ease-in-out 300ms;
  flex-grow: 0;
}

.dashi_datasources_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-grow: 1;
  overflow: auto;
  /* align-items: center; */
}

.dashi_datasource_uploader {
  flex-grow: 0;
  min-width: 60px;
  min-height: 60px;
  height: 60px;
  width: 60px;
  margin: 5px;
  align-self: center;
}

.dashi_datasources:not(.dashi_datasources:has(div.dashi_datasources_container)) > .dashi_datasource_uploader{
  width: 100%;
  height: 100%;
  margin: 5px;
}

.dashi_datasource {
  display: inline-block;
  background-image: var(--gradient-4);
  max-width: 100px;
  min-width: 100px;
  height: 60px;
  max-height: 60px;
  margin: 5px;
}

.xls {
  background-image: var(--gradient-24);
}

.demo_body {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  /* transition: ease-in-out 300ms; */
}

#form-file-upload {
  width: 100%;
  height: 100%;
}
#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.upload-info {
  font-size: medium;
  color: #666;
}

.dashi_datasources:has(div.dashi_datasources_container) .upload-info {
  display: none;
}

.dashi_datasources:has(div.dashi_datasources_container) .upload-button {
  background-color: rgba(127,127,127,0.2);
}

.upload-button:hover {
  box-shadow: var(--shadow-2);
}


.dashi_drilldown_container {
  /* flex-grow: 1; */
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  /* align-items: stretch; */
}

.dashi_widget {
  max-width: calc(50% - 10px);
  margin: 5px;
  padding: 2px;
  background-image: var(--gradient-15);
  border-radius: 12px;
  display: flex;
  overflow: hidden;
}


.onboard_message {
  /* position: fixed;
  left: 0px;
  bottom: 0px; */
  padding: 10px;
  font-size: var(--font-size-4);
  animation: var(--animation-float) forwards;
  animation-timing-function: var(--ease-squish-5);
  animation-duration: 3s;
}
