@import 'open-props/borders';

.dataframe-row {
  border: var(--border-size-1) solid lightgray;
  border-radius: var(--radius-conditional-1);
  margin: 1px;
  display: flex;
  flex-grow: 1;
  justify-content: stretch;
  flex-direction: row;
}

.dataframe-row:nth-child(even) {
  background-color: rgba(128,128,128,0.2);
}

.dataframe-col {
  border: var(--border-size-1) solid lightgray;
  border-radius: var(--radius-conditional-1);
  margin: 1px;
  display: flex;
  flex-grow: 1;
  justify-content: stretch;
  flex-direction: column;
}

.dataframe-col:nth-child(even) {
  background-color: rgba(128,128,128,0.2);
}

.flow div {
  flex-flow: row wrap;
  /* flex-grow: 0;
  flex-wrap: wrap;
  flex-direction: unset; */
}

.dataframe-key {
  font-weight: bolder;
}

.dataframe-value:not(.dataframe-value:only-child) {
  margin: 2px;
  padding: 3px;
  border-radius: var(--radius-2);
  box-shadow: var(--shadow-1);
}

.dataframe-value {
  width: calc(100% - 10px);
}

.flow .dataframe-value {
  width: unset;
}

.widget-result canvas {
  width: 100%;
}