@import 'open-props/animations';

.ask_container {
  backdrop-filter: blur(10px);
  filter: drop-shadow(2px 4px 20px rgba(0,0,0,0.5));
  border-radius: 5px;
  position: fixed;
  background-color: rgba(255,255,255,0.5);
  align-content: center;
  transition: 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

@media (min-width: 640px) {
  .ask_container {
    bottom: 10px;
    right: 10px;
    margin: 10px;
    width: fit-content;
    max-width: 50vw;
    max-height: 50vh;  
  }
}

@media (max-width: 640px) {
  .ask_container {
    bottom: 0px;
    right: 0px;
    max-width: 100vw;
    max-height: 100vh;
    margin: 0px;
  }

  .ask_query_input {
    width: calc(100vw - 50px);
  }
}

.ask_messages_container {
  margin: 5px 5px 0px 5px;
  flex-grow: 1;
  overflow: auto;
}

.ask_message_datayaki {
  background-image: var(--gradient-19);
  background-color: rgb(255, 102, 0);
  border-radius: 20px 20px 0px 20px;
  padding: 10px;
  margin: 10px 10px 10px 50px;
  color: #000;
  float: right;
  clear: both;
  text-align: right;
  transition: ease-in;
}

.ask_message_user {
  background-image: var(--gradient-7);
  background-color: rgb(90, 135, 225);
  border-radius: 20px 20px 20px 0px;
  padding: 10px;
  margin: 10px 50px 10px 10px;
  color: #FFF;
  float: left;
  clear: both;
  text-align: left;
  transition: ease-in;
}

.ask_message_system {
  background-color: rgb(127, 127, 127);
  border-radius: 15px 15px 15px 15px;
  padding: 5px;
  color: #FFF;
  font-size: x-small;
  clear: both;
  width: fit-content;
  max-width: 50%;
  min-width: 150px;
  margin: 10px auto;
  text-align: center;
}

.ask_query_container {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  background-color: rgba(255,255,255,0.5);
  align-items: center;
}

.ask_query_input {
  padding: 0px;
  flex-grow: 1;
  min-width: 25vw;
}

.ask_query_icon {
  animation: var(--animation-shake-x);
  width: 50px;
  overflow: hidden;
  cursor: pointer;
  /* margin: 10px; */
  height: 50px;
  background-image: url('../../static/datayaki-logo.png');
  background-size: cover;
  background-position: center;
  vertical-align: middle;
  font-size: 24pt;
  display: flex;
  justify-content: center;
}

.ask_query_icon > span {
  margin: auto;
}

.ask_message_datayaki_content {
  text-align: left;
}